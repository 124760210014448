export class ElasticLogger {
    constructor() {

    }

    send(data) {
      let dataJson = JSON.stringify(data);
      let link = `${process.env.ROOT_URL}/postClientLogs`;
      let request = new Request(
        link,
        {
          method: "POST", 
          body: dataJson
        }
      );
      fetch(request)
        .then((response) => {
            // Check response result
            if (response.status === 200) {
              console.log('Sent correctly : ', response);
            } else {
              console.log("Le serveur n'a pas pu logger les informations transmises.");
            }
            
        })
        .catch((error) => {
            console.log("Impossible de poster les informations sur ", link, " : ",  error);
        });
  }
}