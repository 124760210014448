<script lang="ts">
    import { onMount } from 'svelte';
    import { replace } from 'svelte-spa-router';
    // import { Connection } from '../scripts/webrtc.js';
    import { ElasticLogger } from '../scripts/monitoring.js';
    import ImgModal from '../components/ImgModal.svelte';
    import Notification from '../components/Notification.svelte';
    import { showModalImg, 
             showImgNotif, 
             numberOfReceivedImages,
             listOfDevices,
             linkToImage, 
             restartCall, 
             sessionState, 
             displayRemoteVideo, showHeader,
             connectionStatus, connectionError } from '../scripts/stores.js';

    import { io } from 'socket.io-client';
    import { translation } from '../stores.js';
    import { getLang } from '../utils/window.js';

    // Get session parameters : room id
    export let params: Record<string, string> = {};
    // Boolean designed to change logs dynamicaly if we are on Altice client side
    let isAltice: boolean = window.location.href.includes('altice');
    let alertNotification = null;
    // Loading Elastic Logger
    let elasticLogger = new ElasticLogger();
    let logData = {
      room: params.room, 
      status: "Connected",
      page: "Camera", 
      message: "Client is connected on camera page",
      distributeur: isAltice ? 'Altice' : 'SFR'
    };
    elasticLogger.send(logData);

    interface LBVideoElement extends HTMLVideoElement {
      setSinkId(id: string);
    }

    // Defining Connection 
    class Connection {
      elasticLogger: any;
      dacUrl: string;
      roomHash: String;
      sessionType: String;
      audioEnabled: String;
      sendMethod: String;
      configuration: any;
      peerConnection: any;
      sendChannel: any;
      receiveChannel: any;
      sendFileChannel: any;
      selectedCamera: any;
      imgReceivedBuffer: any;
      localVideo: any;
      remoteVideo: any;
      // socket: any;
      socket_io: any;
      promiseCredentials: any;
      iceRestartTimeout: any;
      // Stream containing localVideo and sent to other members
      localStream: MediaStream;

      
      /**
       * Initialize RTCPeerObject and event handlers.
       * @param {DacMonitoring} elasticLogger - Monitoring object.
       * @param {String} dacUrl - WebSocket URL for DAC.
       * @param {String} roomHash - Room number.
       * @param {String} sessionType - Session can be camera or screensharing.
       */
      constructor(elasticLogger, dacUrl, roomHash, audioEnabled='Yes') {
        // Setting attributes
        this.elasticLogger = elasticLogger;
        this.dacUrl = dacUrl;
        this.roomHash = roomHash;
        this.sessionType = null;
        this.audioEnabled = audioEnabled;
        this.sendMethod = 'socket';

        this.configuration = null;
        this.peerConnection = null;
        this.sendChannel = null;
        this.sendFileChannel = null;
        this.selectedCamera = null;

        this.imgReceivedBuffer = [];

        this.localVideo = null;
        this.remoteVideo = null;

        // Getting credentials for ICE servers
        this.promiseCredentials = this.getCredentialsForIceServers();
        this.localStream = new MediaStream();
      }
    
      changeDevices() {
        try {
          console.log("Changing camera device...");

          // Stopping current video stream if exists
          if (this.localVideo && this.localVideo.localVideo.srcObject) {
            this.localVideo.localVideo.srcObject.getTracks().forEach((track) => { track.stop(); });
          }

          if (this.peerConnection && this.peerConnection.getSenders().length > 0) {
              navigator.mediaDevices.getUserMedia({video: { deviceId: this.selectedCamera}, audio: false })
                .then((s) => {
                  if (this.audioEnabled === 'No') {
                    s.getAudioTracks().forEach(track => {track.enabled = false;});
                  }
                  console.log('Setting new media stream ...');
                  this.setMediaStream(s);
                  this.peerConnection.getSenders().forEach((sender) => {
                    if (sender.track && sender.track.kind === 'video') {
                      sender.replaceTrack(s.getVideoTracks()[0]);
                    } else if (sender.track && sender.track.kind === 'audio') {
                      sender.replaceTrack(s.getAudioTracks()[0]);
                    }
                  });
                  console.log('Senders track replaced.');
                })
                .catch(err => {
                  console.log('Error while switching device : ', err);
                });
          }
          // else {
          //   //adapt for samsung camera issue
          //   this.initializeMediaStream();
          // }
        } catch (err) {
          console.log(err);
        }
      }

      /**
       * Closing WebRTC and socket connection.
       */
      close() {
        
        this.localVideo.localVideo.srcObject.getTracks().forEach( (track) => {
          track.stop();
        });
        if (this.peerConnection) {
          this.peerConnection.close();
          this.peerConnection = null;
        }
        // this.socket.close();
        this.socket_io.close();
        // Redirecting
        replace(`/feedback/${this.roomHash}?lang=${getLang()}`);
      }    

      /**
       * Setting call by creating and sending RTCPeerConnection offer.
       */
       createOffer(iceRestart = false) {
        console.log('Creating ICE Offer...');
          this.peerConnection.createOffer({iceRestart: iceRestart})
          .then((offer) =>{
            this.peerConnection.setLocalDescription(offer);
            console.log("Senders in offer : ", this.peerConnection.getSenders());
            // this.socket.send(JSON.stringify({
            //   room: this.roomHash,
            //   type: 'connection',
            //   action: 'ICEOffer',
            //   sdp: offer
            // }));
            this.socket_io.emit("message", {
              room: this.roomHash,
              type: 'connection',
              action: 'ICEOffer',
              sdp: offer
            });
            this.iceRestartTimeout = setTimeout(() => {this.createOffer(true)}, 5000);
          })
          .catch((err) => {
              console.log(err);
          });
      }

      createPeerConnection() {
        try {
          // Closing RTCPeerConnection object if exists
          if (this.peerConnection !== null) {
            try {
              this.peerConnection.close();
            } catch (error) {
              console.log('Error on closing RTCPeerConnection object: ', error);
            } finally {
              this.peerConnection = null;
            }
          }

          // Creating RTC Peer Connection and setting handlers
          // Setting ICE servers configuration
          this.promiseCredentials.then(function success(credentials) {
            this.configuration = {
              'iceServers': [
                {urls: ['stun:stun1.sd-sfr.fr:443']},
                {
                  'credential': credentials.password,
                  'username': credentials.user,
                  'credentialType': "password",
                  urls: [
                    'turn:stun1.sd-sfr.fr:443',
                    'turns:stun1.sd-sfr.fr:443'
                  ],
                },
              ],
            };

            console.log('PEER CONFIG ----- ', this.configuration);
            this.peerConnection = new RTCPeerConnection(this.configuration);
            this.peerConnection.onicecandidate = (event) => {
              if (event.candidate && event.candidate.candidate.length > 0) {
                // this.socket.send(JSON.stringify({
                //   room: this.roomHash,
                //   action: 'ice',
                //   type: 'candidate',
                //   label: event.candidate.sdpMLineIndex,
                //   id: event.candidate.sdpMid,
                //   candidate: event.candidate.candidate,
                // }));
                this.socket_io.emit("message", {
                  room: this.roomHash,
                  action: 'ice',
                  type: 'candidate',
                  label: event.candidate.sdpMLineIndex,
                  id: event.candidate.sdpMid,
                  candidate: event.candidate.candidate,
                });
              } else {
                console.log('All ICE candidate have been sent.');
              }
            };
            this.peerConnection.onicecandidateerror = (event) => {
              console.log('ICE error : ', event);
            };
            // Not compatible with Firefox for now
            this.peerConnection.onconnectionstatechange = (event) => {
              console.log('Changed ICE connection state : ',event.target.connectionState)
              switch (event.target.connectionState) {
                case 'connected':
                  // The connection has become fully connected
                  // Enable buttons
                  this.localVideo.enableBtns();
                  connectionStatus.set('Connected');
                  clearTimeout(this.iceRestartTimeout);
                  break;
                case 'disconnected':
                  // The connection has been disconnected
                  console.log('Connection status has changed to : disconnected');
                  break;
                case 'failed':
                  // ICE transports has terminated unexpectedly or in an error
                  console.log('Connection status has changed to : failed');
                  break;
                case 'closed':
                  // The connection has been closed
                  console.log('Connection status has changed to : closed');
                  break;
              }
            };
            this.peerConnection.oniceconnectionstatechange  = (event) => {
              switch (event.target.connectionState) {
                case 'connected':
                  // The connection has become fully connected
                  // Enable buttons
                  this.localVideo.enableBtns();
                  connectionStatus.set('Connected');
                  break;
                case 'disconnected':
                  // The connection has been disconnected
                  console.log('Connection status has changed to : disconnected');
                  break;
                case 'failed':
                  // ICE transports has terminated unexpectedly or in an error
                  console.log('Connection status has changed to : failed');
                  break;
                case 'closed':
                  // The connection has been closed
                  console.log('Connection status has changed to : closed');
                  break;
              }
            };

            // Setting handlers for data and streams from remote peer
            this.peerConnection.ontrack = (event) => {
              // Adding track or stream
              if (event.streams && event.streams[0]) {
                this.remoteVideo.srcObject = event.streams[0];
              } else {
                  this.remoteVideo.srcObject = new MediaStream();
                  this.remoteVideo.srcObject.addTrack(event.track);
              }
            };
            this.peerConnection.ondatachannel = (event) => {
              console.log('Remote peer created new data channel');

              if (event.channel.label == 'sendFile') {
                console.log("Received File...");
                this.sendFileChannel = event.channel;
                this.sendFileChannel.binaryType = "arraybuffer";
                this.sendFileChannel.onmessage = (event) => {
                  const {data} = event;
                  try {
                    if (data != 'EOF') {
                      this.imgReceivedBuffer.push(new Uint8Array(data));
                    } else {
                      const arrayBuffer = this.imgReceivedBuffer.reduce((acc, arrayBuffer) => {
                        //create a type array of 8-bit unsigned integers
                        const tmp = new Uint8Array(acc.byteLength + arrayBuffer.byteLength);
                        //copy values into array starting at index 0
                        tmp.set(new Uint8Array(acc), 0);
              
                        tmp.set(new Uint8Array(arrayBuffer), acc.byteLength);
                        return tmp;
                      }, new Uint8Array());
                      const blob = new Blob([arrayBuffer]);

                      // Updated number of images received
                      numberOfReceivedImages.update(n => n + 1);

                      // Setup new image received notification
                      showImgNotif.set(true);
                      setTimeout(() => {
                        showImgNotif.set(false);
                      }, 3500);

                      // Adding image to side panel
                      const blobUrl = window.URL.createObjectURL(blob);
                      let img = document.createElement("img");
                      img.src = blobUrl;
                      img.onclick = (event) => {
                        linkToImage.set((<HTMLObjectElement>event.target).getAttribute('src'));
                        showModalImg.set(true);
                      };
                      document.querySelector('#side-panel').appendChild(img);
                      
                      this.imgReceivedBuffer = [];
                    }
                  } catch(err) {
                    console.log('Error while receiving image : ', err);
                  }
                }
                this.sendFileChannel.onopen = (event) => {
                  console.log('Send file channel opened.');
                }
                this.sendFileChannel.onclose = (event) => {
                  console.log('Send file channel closed.');
                }
              } else {
                    const self = this;
                // Getting the RTCDataChannel associated with the event
                this.receiveChannel = event.channel;
                // Setting receiveChannel events handlers
                this.receiveChannel.onmessage = (event) => {
                   console.log('event data', event.data)
                  const data = JSON.parse(event.data);
                  console.log('data', data)
                  switch (data.type) {
                    case 'video':
                      if (data.action === 'pause') {
                        this.localVideo.pauseVideo('remote');
                      } else if (data.action === 'resume') {
                        this.localVideo.resumeVideo('remote');
                      } else if (data.action === 'hangup') {
                        this.close();
                      }
                      break;
                    case 'geoloc':
                    if(data.action === 'request'){
                         const options = {
                            enableHighAccuracy: true,
                            timeout: 5000,
                            maximumAge: 0,
                          };

                          function success(pos) {
                            const crd = pos.coords;
                             const geolocalisation = document.getElementById('geolocalisation');
                             geolocalisation.innerHTML = `<strong>${translation.geolocalisation.latitude}</strong> : ${crd.latitude.toFixed(4)} &nbsp;&nbsp; <strong>   ${translation.geolocalisation.longitude}</strong> : ${crd.longitude.toFixed(4)}`;
                             self.sendChannel.send(JSON.stringify({type: 'geoloc', action: 'send', Latitude : crd.latitude, Longitude: crd.longitude }))
                          }

                          function error(err) {
                            console.warn(`ERROR(${err.code}): ${err.message}`);
                            alertNotification = translation.alert.activePosition;
                            self.sendChannel.send(JSON.stringify({type: 'geoloc', action: 'send', msg: translation.alert.activePosition}))
                           
                          }
                          navigator.geolocation.getCurrentPosition(success, error, options);
                         

                    }
                    case 'connection':
                      if (data.action === 'ICEOffer') {
                        console.log('Received offer from remote peer.');
                        this.receivedOffer(data.sdp);
                      } else if (data.action === 'ICEAnswer') {
                        console.log('Received answer from remote peer.');
                        this.receivedAnswer(data.sdp);
                      }
                      break;
                    case 'pointer':
                      this.localVideo.updatePointer(data.data);
                  }
                };
                this.receiveChannel.onopen = (event) => {
                  console.log(
                      'Receive Channel status has changed to ',
                      this.receiveChannel.readyState,
                  );
                };
                this.receiveChannel.onclose = (event) => {
                  console.log(
                      'Receive Channel status has changed to ',
                      this.receiveChannel.readyState,
                  );
                };
              }
            };

            // Creating RTC data channel and setting handlers
            console.log('Creating data channel... ');
            this.sendChannel = this.peerConnection.createDataChannel(
                'sendFromMoss',
            );
            this.sendChannel.onopen = (event) => {
              console.log(
                  'Send Channel status has changed to ',
                  this.sendChannel.readyState,
              );
              this.sendMethod = 'channel';
              console.log('Send channel opened.');
            };
            this.sendChannel.onclose = (event) => {
              console.log(
                  'Send Channel status has changed to ',
                  this.sendChannel.readyState,
              );
              this.sendMethod = 'socket';
              console.log('Send channel closed.');
            };
          }.bind(this));
          
        } catch (err) {
          console.log("Error on creation of PeerConnection Object : ", err);
          connectionError.set('Error on creation of PeerConnection Object.');        
        }
      }

      /**
        * This function gets the credentials for the ICE servers.
        */
      async getCredentialsForIceServers() {
        const request = new Request(`${process.env.ROOT_URL}/credentials`, {method: 'GET'});
    
        const response = await fetch(request);
        const credentials = await response.json();
    
        return credentials;
      }

      /**
       * Send message to remote peer.
       * @param {*} msg - Message to be sent.
       */
      send(msg) {
        if (this.sendMethod === 'socket') {
          // this.socket.send(msg);
          this.socket_io.emit("message", msg);
        } else if (this.sendMethod === 'channel') {
          msg = JSON.stringify(msg);
          this.sendChannel.send(msg);
        }
      }

      /**
       * Setter for local video.
       * @param {*} video - Video object DOM.
       */
      setLocalVideo(video) {
        this.localVideo = video;
      }

      setMediaStream(stream) {
        

        // Setting up retrieved media stream
        // if (this.sessionType !== 'clientspace') {
        this.localVideo.setStream(stream);
        // }
        
        // Adding tracks (to send to remote peer) to peerConnection
        if (this.peerConnection) {
            for (const track of stream.getVideoTracks()) {
              console.log('Adding video tracks...');
              this.peerConnection.addTrack(track, this.localStream);
            }
            for (const track of stream.getAudioTracks()) {
                console.log('Adding audio tracks...');
                this.peerConnection.addTrack(track, this.localStream);
            }
            console.log('Tracks has been added to RTCPeerConnection object.');
        }
      }

      setRemoteVideo(remoteVideo) {
        this.remoteVideo = remoteVideo;
      }

      startCall() {
        console.log('Starting call...');
        // Stopping current video stream if exists
        if (this.sessionType !== 'clientspace' && this.localVideo && this.localVideo.localVideo.srcObject) {
          this.localVideo.localVideo.srcObject.getTracks().forEach((track) => { track.stop(); });
        }

        if (this.sessionType === 'video') {
          const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
          let constraints = {
            video: {},
            audio: false,
          };
          // Setting up media stream constraints
          if (this.selectedCamera === null && supportedConstraints.facingMode) {
            constraints.video['facingMode'] = {ideal: 'environment'};
          }
          else if (supportedConstraints.deviceId) {
              constraints.video['deviceId'] = this.selectedCamera;
              console.log('constraints.video.deviceId :  ', constraints.video['deviceId']);
          }
          if (Object.keys(constraints.video).length === 0) {
              constraints.video = true;
          }

          // Loading user media streams
          navigator.mediaDevices.getUserMedia(constraints)
            .then((mediaStream) => {
                // Setting media stream and adding tracks to RTCPeerConnection
                console.log('Got media stream !');
                if (this.audioEnabled === 'No') {
                  mediaStream.getAudioTracks().forEach(track => {track.enabled = false;});
                }
                this.setMediaStream(mediaStream);
                connectionStatus.set('Connected');
            })
            .then(() => {
                // Setting up available devices list
                listOfDevices.set([]);
                navigator.mediaDevices.enumerateDevices()
                  .then((videoDevices) => {
                    for (let i = 0; i < videoDevices.length; i++) {
                      const camera = videoDevices[i];
                      if (camera.kind == 'videoinput') {
                        let newDevice = {
                          id: camera.deviceId, 
                          label: camera.label,
                        }
                        // Trying to detect front and back camera
                        if (camera.label.toLowerCase().includes('front') || camera.label.toLowerCase().includes('avant')) {
                          newDevice.label = 'Caméra Avant';
                        }
                        else if (camera.label.toLowerCase().includes('back') || camera.label.toLowerCase().includes('arrière')) {
                          newDevice.label = 'Caméra Arrière';
                        }
                        listOfDevices.update(l => [...l, newDevice]);
                      }
                    }
                  })
                  .catch((err) => console.log('Error enumerating devices: ', err));
                // Starting call by sending offer to remote peer
                console.log("Senders camera : ", this.peerConnection.getSenders());
                this.createOffer();
            })
            .catch((error) => {
              if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                connectionStatus.set('Error');
                connectionError.set(`<br>${translation.alert.permissionDeniedError1}<br><br>${translation.alert.permissionDeniedError2}`);
              }
                // console.log(error);
                // let errorText = null;
                // if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
                //     errorText = 'No media track found matching given constraints.';
                //     // Loading user media streams with basic constraints
                //     let constraints = {video: true, audio: false};
                //     navigator.mediaDevices.getUserMedia(constraints)
                //         .then((mediaStream) => {
                //             // Setting media stream and adding tracks to RTCPeerConnection
                //             this.gotLocalMediaStream(mediaStream);
                //         })
                //         .then(() => {
                //             // Setting up available devices list
                //             if (NAV_ELEMENTS.devicesList) {
                //                 navigator.mediaDevices.enumerateDevices()
                //                     .then((result) => {
                //                         this.getCameraDevices(result);
                //                     });
                //             }
                //             // Starting call by sending offer to remote peer
                //             this.createOffer();
                //         })
                //         .catch(err => {
                //             console.log(err)
                //             let savedError = {
                //                 room: this.roomHash,
                //                 name: error.name,
                //                 msg: error.message
                //             }
                //             logErrToElastic(savedError);
                //         })
                // }
                // else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
                //     errorText = 'Device already in use or unreadable.'

                //     // Loading user media streams with basic constraints
                //     let constraints = {video: true, audio: false};
                //     navigator.mediaDevices.getUserMedia(constraints)
                //         .then((mediaStream) => {
                //             // Setting media stream and adding tracks to RTCPeerConnection
                //             this.gotLocalMediaStream(mediaStream);
                //         })
                //         .then(() => {
                //             // Setting up available devices list
                //             if (NAV_ELEMENTS.devicesList) {
                //                 navigator.mediaDevices.enumerateDevices()
                //                     .then((result) => {
                //                         this.getCameraDevices(result);
                //                     });
                //             }
                //             // Starting call by sending offer to remote peer
                //             this.createOffer();
                //         })
                //         .catch(err => {
                //             console.log(err)
                //             let savedError = {
                //                 room: this.roomHash,
                //                 name: error.name,
                //                 msg: error.message
                //             }
                //             logErrToElastic(savedError);
                //         })

                // }
                // else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
                //     errorText = "Available devices can't statisfy specified constraints.";
                // }
                // else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                //     errorText = 'Camera access refused.';
                // }
                // else if (error.name == "TypeError" || error.name == "TypeError") {
                //     errorText = 'Constraints error (check if not empty)';
                // }
                // else {
                //     errorText = 'Error getting media track.';
                // }

                // let savedError = {
                //     room: this.roomHash,
                //     name: error.name,
                //     msg: (errorText + ' ' + error.message)
                // }
                // logErrToElastic(savedError);
            });
        } else if (this.sessionType === 'screensharing') {          
          // Getting screen media stream
          let constraints = {
            video: true,
            audio: false
          };
          navigator.mediaDevices.getDisplayMedia(constraints)
            .then((mediaStream) => {
              this.localVideo.screenshareBtn = false;
              updateScreenShareBtn(false);
              // Setting media stream and adding tracks to RTCPeerConnection
              if (this.audioEnabled === 'No') {
                mediaStream.getAudioTracks().forEach(track => {track.enabled = false;});
              }
              mediaStream.getVideoTracks().forEach((track) => {
                track.onended = () => {
                  this.localVideo.pauseVideo('local');
                  this.localVideo.screenshareBtn = true;
                  updateScreenShareBtn(true);
                }
              });
              this.setMediaStream(mediaStream);
              connectionStatus.set('Connected');
            }).then(() => {
              // Starting call by sending offer to remote peer
              this.createOffer();
            })
            .catch((err) => {
              console.log('Error loading display media : ', err);
            });
        } else if (this.sessionType === 'clientspace') {          
          // Getting screen media stream
          let constraints = {
            video: {
              cursor: "always",
              displaySurface: "browser"
            },
            audio: false
          };
          navigator.mediaDevices.getDisplayMedia(<MediaStreamConstraints>constraints)
            .then((mediaStream) => {
              this.localVideo.screenshareBtn = false;
              updateScreenShareBtn(false);
              // Setting media stream and adding tracks to RTCPeerConnection
              if (this.audioEnabled === 'No') {
                mediaStream.getAudioTracks().forEach(track => {track.enabled = false;});
              }
              mediaStream.getVideoTracks().forEach((track) => {
                track.onended = () => {
                  this.localVideo.pauseVideo('local');
                  this.localVideo.screenshareBtn = true;
                  updateScreenShareBtn(true);
                }
              });
              this.setMediaStream(mediaStream);
             
              connectionStatus.set('Connected');
              
            })
            .then(() => {
              // Starting call by sending offer to remote peer
              this.createOffer();
            })
            .catch((err) => {
              console.log('Error loading display media : ', err);
            });
          
          // Getting microphone stream and adding it to peerConnection object
          navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then((audioStream) => {
              if (this.peerConnection) {
                for (const track of audioStream.getAudioTracks()) {
                  this.peerConnection.addTrack(track, this.localStream);
                  this.localVideo.localVideo.srcObject.addTrack(track);
                }
              }
              if (this.audioEnabled === 'No') {
                audioStream.getAudioTracks().forEach(track => {track.enabled = false;});
              }
              // Starting call by sending offer to remote peer
              this.createOffer();
            })
            .catch((err) => {
              console.log('Error loading microphone : ', err);
            })
        }
        
      }

      /**
       * Method starting the WebRTC call through WebSockets.
       * ! LocalVideo element has already been set before this method is called. 
       */
      startSocket() {
          // Setting websocket object
          try {
            this.socket_io = io(`${process.env.ROOT_URL}`);
            // this.socket = new WebSocket(this.dacUrl);
            // this.socket.onopen = (event) => {
            //   this.socket.send(JSON.stringify({
            //     action: 'subscribe',
            //     type: 'client',
            //     room: this.roomHash,
            //   }));
            //   // Log WebSocket connection
            //   let logData = {
            //     room: this.roomHash, 
            //     status: "WebSocketConnected",
            //     page: "Camera",
            //     message: "Client is connected to websocket server and asked for subscription."
            //   };
            //   this.elasticLogger.send(logData);
            //   console.log('Websocket is connected ! Room number:', this.roomHash);
            // };

            this.socket_io.on('connect', () => {
              this.socket_io.emit("message", {
                action: 'subscribe',
                type: 'client',
                room: this.roomHash,
              });
              // Log WebSocket connection
              let logData = {
                room: this.roomHash, 
                status: "SocketConnected",
                page: "Camera",
                message: "Client is connected to socket server and asked for subscription.",
                distributeur: isAltice ? 'Altice' : 'SFR'
              };
              this.elasticLogger.send(logData);
              console.log('Socket is connected ! Room number:', this.roomHash);
            });
        
            // this.socket.onclose = (event) => {
            //   console.log('Web socket connection closed');
            //   if (this.sendChannel && this.sendChannel.readyState === 'open') {
            //     this.sendMethod = 'channel';
            //   }
            // };

            this.socket_io.on('disconnect', () => {
              console.log('Socket connection closed');
              if (this.sendChannel && this.sendChannel.readyState === 'open') {
                this.sendMethod = 'channel';
              }
            });
        
            // this.socket.onerror = (error) => {
            //   console.log('WebSocket Error: ', error);
            // };

            this.socket_io.on('connect_error', (error) => {
              console.log('Socket.io connect_error: ', error.message);
            });

        
            // this.socket.onmessage = (event) => {
            //   // Parse message
            //   const objData = JSON.parse(event.data);
              
            //   if (objData.action === 'JoinedRoom') {
            //     // Initialize WebRTC connection
            //     console.log('You have just joined the room.');
            //     // Set webrtc session information
            //     this.sessionType = objData.sessionType;
            //     this.audioEnabled = objData.audioEnabled;
            //     console.log('Session type : ', this.sessionType);
            //     // Starting call if session is camera or waiting for user to click
            //     if (this.sessionType === 'video') {
            //       this.startCall();
            //     } else if (this.sessionType === 'screensharing') {
            //       sessionState.update(oldState => {
            //         oldState.sessionType = 'screensharing';
            //         return oldState;
            //       });
            //     } else if (this.sessionType === 'clientspace') {
            //       sessionState.update(oldState => {
            //         oldState.sessionType = 'clientspace';
            //         return oldState;
            //       });
            //       showHeader.set(false);
            //     }
                
                
            //   } else if (objData.action === 'ICEOffer') {
            //     // Handle WebRTC offer
            //     console.log('Received offer from remote peer.');
            //     this.receivedOffer(objData.sdp);
            //   } else if (objData.action === 'ICEAnswer') {
            //     // Handle WebRTC answer
            //     console.log('Received answer from remote peer.');
            //     this.receivedAnswer(objData.sdp);
            //   } else if (objData.action === 'Rejected') {
            //     // Rejected WebSocket connection
            //     connectionError.set('Cette session est complète ou inexistante.');
            //   } else if (objData.type === 'candidate') {
            //     // Adding new ICE candidate
            //     console.log('Received a candidate.');
            //     let candidate = new RTCIceCandidate({
            //       sdpMLineIndex: objData.label,
            //       candidate: objData.candidate,
            //     });
            //     this.peerConnection.addIceCandidate(candidate);
            //   } else if (objData.action === 'ping') {
            //     // Keeping WebSocket alive
            //     this.socket.send(JSON.stringify({
            //       action: 'pong',
            //     }));
            //   }
            // }; // end of this.socket.onmessage


            this.socket_io.on('message', (objData) => {
              
              if (objData.action === 'JoinedRoom') {
                // Initialize WebRTC connection
                console.log('You have just joined the room.');
                // Set webrtc session information
                this.sessionType = objData.sessionType;
                this.audioEnabled = objData.audioEnabled;
                console.log('Session type : ', this.sessionType);
                // Starting call if session is camera or waiting for user to click
                if (this.sessionType === 'video') {
                  this.startCall();
                } else if (this.sessionType === 'screensharing') {
                  sessionState.update(oldState => {
                    oldState.sessionType = 'screensharing';
                    return oldState;
                  });
                } else if (this.sessionType === 'clientspace') {
                  sessionState.update(oldState => {
                    oldState.sessionType = 'clientspace';
                    return oldState;
                  });
                  showHeader.set(false);
                }
                
                
              } else if (objData.action === 'ICEOffer') {
                // Handle WebRTC offer
                console.log('Received offer from remote peer.');
                this.receivedOffer(objData.sdp);
              } else if (objData.action === 'ICEAnswer') {
                // Handle WebRTC answer
                console.log('Received answer from remote peer.');
                this.receivedAnswer(objData.sdp);
              } else if (objData.action === 'Rejected') {
                // Rejected WebSocket connection
                connectionError.set(translation.alert.sessionFullError);
              } else if (objData.type === 'candidate') {
                // Adding new ICE candidate
                console.log('Received a candidate.');
                let candidate = new RTCIceCandidate({
                  sdpMLineIndex: objData.label,
                  candidate: objData.candidate,
                });
                this.peerConnection.addIceCandidate(candidate);
              } else if (objData.action === 'ping') {
                // Keeping WebSocket alive
                this.socket_io.emit('message', {
                  action: 'pong',
                });
              }
              else if (objData.action === 'offline') {
               window.location.reload();
            }
            }); // end of this.socket.onmessage
          
          } catch (err) {
            console.log('Websocket Error : ', err);
            connectionError.set('Websocket Error: ' + err);
          }
          
      }

      /**
       * This function sets the remote SDP description when answer is received.
       * @param {*} sdp
       */
      receivedAnswer(sdp) {
        console.log('Received answer, setting RTC remote description...');
        this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdp))
          .then(() => {
            console.log('RTC remote description is set.');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      /**
       * This function sets the remote SDP description when offer is received.
       * @param {*} sdp
       */
      receivedOffer(sdp) {
        console.log(
            'Received offer, ',
            'setting RTC remote description, and creating answer...',
        );
        this.peerConnection.setRemoteDescription(new RTCSessionDescription(sdp))
            .then(() => {
              console.log('Creating and sending answer...');
              console.log(this.peerConnection);
              this.peerConnection.createAnswer()
                  .then((answerSdp) => {
                    this.peerConnection.setLocalDescription(answerSdp);
                    const data = {
                      room: this.roomHash,
                      type: 'connection',
                      action: 'ICEAnswer',
                      sdp: answerSdp,
                    };
                    this.send(data);
                  })
                  .catch((error) => {
                    console.log('WebRTC error on answer creation: ', error);
                  });
            })
            .catch((error) => {
              console.log(error);
            });
      }
    }

    // Defining LocalVideo manager
    class LocalVideo {
      connection: Connection;

      localVideo: HTMLVideoElement;
      pauseBtn: HTMLButtonElement;
      resumeBtn: HTMLButtonElement;
      micBtn: HTMLButtonElement;
      flashBtn: HTMLButtonElement;
      hangupBtn: HTMLButtonElement;
      pointer: HTMLImageElement;
      soundBtn: HTMLButtonElement;

      isPausedBy: string;
      micIsPaused: boolean;
      micIsDisabled: boolean;
      btnIsDisabled: any;
      screenshareBtn: boolean;
      currentSpeakerPos: any;
      audioOutputs: any;
      currentSpeaker: any;

      /**
       * Constructor sets DOM objects.
       */
      constructor(connection) {
        this.connection = connection;
        this.isPausedBy = null;
        this.micIsPaused = false; 
        this.micIsDisabled = true;
        this.btnIsDisabled = {
          pause: true,
          mic: true,
          hangup: true
        };
        this.screenshareBtn = false;

        this.currentSpeakerPos = 0;
        this.currentSpeaker = null;
        this.audioOutputs = null;
        navigator.mediaDevices.enumerateDevices().then(this.setAudioOutput.bind(this));
      } 

      /**
       * Enabling buttons when call has started.
       */
      enableBtns() {
        console.log('Enabling buttons....');
        this.btnIsDisabled.pause = false;
        this.btnIsDisabled.mic = "";
        this.btnIsDisabled.hangup = false;
        this.micIsDisabled = false;
        // @TODO check if flash is available then unhide/hide flash btn

      }

      pauseVideo(who) {
        if (this.localVideo.srcObject) {
          (<MediaStream>this.localVideo.srcObject).getTracks().forEach((track) => { 
            if (track.kind === 'video') {
              track.enabled = false; 
            }
          });
        }   
        this.isPausedBy = who;
        if (who === 'local') {
          const data = {
          'type': 'video',
          'action': 'pause'
          }
          this.connection.send(data);
        }
      }

      resumeVideo(who) {
        if (this.isPausedBy === who) {
          this.isPausedBy = null;
          (<MediaStream>this.localVideo.srcObject).getTracks().forEach((track) => { 
            if (track.kind === 'video') {
              track.enabled = true; 
            }
          });
          const data = {
          'type': 'video',
          'action': 'resume'
          };
          this.connection.send(data);
        } else {
          // @TODO reject. 
        }
      }

      pauseMicrophone() {
        this.micIsPaused = true;
        (<MediaStream>this.localVideo.srcObject).getTracks().forEach((track) => { 
            if (track.kind === 'audio') {
              track.enabled = false; 
            }
        });
      }

      resumeMicrophone() {
        this.micIsPaused = false;
        (<MediaStream>this.localVideo.srcObject).getTracks().forEach((track) => { 
            if (track.kind === 'audio') {
              track.enabled = true; 
            }
        });
      }

      setStream(stream) {
        this.localVideo.srcObject = stream;
      }

      setVideo(event) {
        // Adding track or stream
        if (event.streams && event.streams[0]) {
          this.localVideo.srcObject = event.streams[0];
        } else {
            this.localVideo.srcObject = new MediaStream();
            this.localVideo.srcObject.addTrack(event.track);
        }
        // @TODO Changing play button display to green
      }

      setAudioOutput(devices) {
        this.audioOutputs = devices.filter(device => device.kind === 'audiooutput');
        this.currentSpeaker = this.audioOutputs[0];
        console.log("Audio outputs detected : ", this.audioOutputs);
      }

      toggleSound() {
        this.currentSpeakerPos += 1;
        if (this.currentSpeakerPos === this.audioOutputs.length) {
            this.currentSpeakerPos = 0;
        }
        if (this.audioOutputs[this.currentSpeakerPos] && this.currentSpeaker) {
          while (this.audioOutputs[this.currentSpeakerPos].groupId === this.currentSpeaker.groupId) {
            this.currentSpeakerPos += 1;
            if (this.currentSpeakerPos === this.audioOutputs.length) {
              this.currentSpeakerPos = 0;
              break;
            }
          }
          this.currentSpeaker = this.audioOutputs[this.currentSpeakerPos];
          try {
            (<LBVideoElement>this.localVideo).setSinkId(this.currentSpeaker.deviceId).then(() => console.log('Changed speaker to ', this.currentSpeaker));
          } catch (err) {
            console.log("Error executing setSinkId function : ", err);
          }
          
        } else {
          console.log('Cannot find audio output devices.');
        }
      }

      hangup() {
        (<MediaStream>this.localVideo.srcObject).getTracks().forEach( (track) => {
          track.stop();
        });
        const data = {
          'type': 'video',
          'action': 'hangup'
          }
          this.connection.send(data);
          this.connection.close();
      }

      /**
       * Update pointer position. 
       * @param newPosition (object) - Contains new position according to video size.
       */
      updatePointer(newPosition) {
        if (this.pointer.style.visibility === 'hidden') {
          this.pointer.style.visibility = 'visible';
        }
        let videoPos = this.localVideo.getBoundingClientRect();
        console.log('Updating pointer...', videoPos);
        if (this.connection.sessionType == 'clientspace') {
          videoPos = document.querySelector('html').getBoundingClientRect();
        }
        
        // Computing correct position
        let left = videoPos.left + newPosition.x * (videoPos.right - videoPos.left) / newPosition.videoX;
        let top = videoPos.top + newPosition.y * (videoPos.bottom - videoPos.top) / newPosition.videoY;

        left -= this.pointer.width / 2;
        top -= this.pointer.height / 2;
        // Setting pointer to position
        this.pointer.style.left = left + 'px';
        this.pointer.style.top = top + 'px';
      }
    }

    let show = {
      modalImg: false,
      divImgNotif: false,
      linkToImage: null
    };

    showModalImg.subscribe(value => {
      show.modalImg = value;
    });
    showImgNotif.subscribe(value => {
      show.divImgNotif = value;
    });
    linkToImage.subscribe(value => {
      show.linkToImage = value;
    });
    let showScreenSharingBtn = false;
    function updateScreenShareBtn(value) {
      showScreenSharingBtn = value;
    }
    let dacUrl = `${process.env.WS_URL}?roomId=${params.room}`;

    
    let connection = new Connection(elasticLogger, dacUrl, params.room, 'fr');
    let localVideo = new LocalVideo(connection);
    
    let remoteVideo; 
    
    let isClientSpace = null;
    
    onMount(() => {
      connection.setLocalVideo(localVideo);
      connection.setRemoteVideo(remoteVideo);
      connection.createPeerConnection();
      connection.startSocket();

      restartCall.subscribe(value => {
        connection.selectedCamera = value.params;
        console.log('Selected Camera : ', connection.selectedCamera);
        connection.changeDevices();
      });
      
      sessionState.subscribe(state => {
        if (state.sessionType === 'screensharing' || state.sessionType === 'clientspace') {
          localVideo.screenshareBtn = true;
          updateScreenShareBtn(true);
        }
        if (state.sessionType === 'clientspace') {
          isClientSpace = true;
        }
      });
    });



</script>

<style>
  #video-container {
    margin: auto;
    align-items: stretch;
    width: 95vw;
  }

  #pointer {
    position: absolute;
    z-index: 2;
  }

  .control-bar-icons {
    font-size: 28px;
  }

  #connection-status, #connection-error {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .color-alt {
    color: var(--background-alt);
  }
  #geolocalisation {
    display: flex;
    justify-content: center;
  }

  @media screen and (min-width: 800px) {

    #video-container video {
      height: 75vh;
    }

    #video-container {
      width: max-content;
    }
  }
</style>

<Notification  bind:alertNotification />
<!-- New image received notification -->
{#if show.divImgNotif}
<div id="new-img-notification" style="position:absolute;bottom:0;width:100vw;" class="bg-green-700 text-teal-900 px-4 py-3" role="alert">
  <div class="flex">
    <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <p class="text-sm m-auto ml-0">{translation.camera.newImageNotification}</p>
  </div> 
</div>
{/if}

<!-- Modal for displaying image -->
<ImgModal bind:show={show.modalImg} bind:imgSrc={show.linkToImage} />

<!-- Screensharing button -->
{#if showScreenSharingBtn}
<div id="screenshare-activation" class="m-auto mt-5 text-center space-y-2">
  <p>{translation.camera.screenSharingButton1}</p>
  <button class="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-4 rounded" on:click={_e => connection.startCall()} >{translation.camera.screenSharingButton2}</button>
</div>
{/if}

{#if !showScreenSharingBtn && isClientSpace}
<iframe id="clientspace" title="SFR client space" src="https://www.sfr.fr/cas/login?service=https%3A%2F%2Fwww.sfr.fr%2Faccueil%2Fj_spring_cas_security_check"
    width="100%" height="100%" style="border: solid 10px; border-color:brown">
</iframe>
{/if}

<!-- Video Container -->
<div id='video-container' class="flex flex-col justify-center flex-grow">
  
    <div id="geolocalisation"></div>
    <video bind:this="{localVideo.localVideo}" autoplay playsinline loop muted 
        class="w-full rounded-t-lg bg-gray-500 bg-opacity-75" class:hidden={$connectionStatus === 'Error' || isClientSpace}>
    </video>

    {#if !isClientSpace}
    <video bind:this={remoteVideo} autoplay playsinline loop  
        class="w-full rounded-t-lg bg-gray-500 bg-opacity-75" class:hidden={!$displayRemoteVideo}>
      <track kind="captions">
    </video>
    {/if}
    {#if false}
    <!-- Spinner -->
    <div id='connection-status' class="flex flex-col w-max justify-center items-center" class:hidden={$connectionStatus !== 'Loading'}>
      <div class="animate-spin rounded-full h-20 w-20 border-b-2 border-blue-900"></div>
      <p class="text-gray-900 text-lg mt-3">{translation.alert.spinner}</p>
    </div>
    {/if}
    <!-- Connection Error -->
    <div id="connection-error" style="position:absolute;bottom:0;width:100vw;" class="flex flex-col w-full justify-center items-center bg-yellow-100 text-yellow-700 p-4" role="alert" class:hidden={$connectionStatus !== 'Error'}>
      <i class="fa fa-exclamation-triangle text-4xl" aria-hidden="true"></i>
      <p class="font-bold">{translation.alert.connexionError}</p>
      <p class="text-center">{@html $connectionError}</p>
    </div>
  <!-- Pointer -->
  <img id='pointer' bind:this="{localVideo.pointer}" src="img/pointeur.png" alt="Red Pointer" width="10" height="10" style="visibility: hidden;" />
  <div id="video-control-bar" class="h-16 flex flex-row justify-around items-center rounded-b-lg bg-gray-500 bg-opacity-75" 
       style="min-width: 300px;" class:hidden={$connectionStatus === 'Error'}>
    <!-- Pause and Resume -->
    {#if (localVideo.isPausedBy == null)}
    <button  class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" on:click={_e => {localVideo.pauseVideo('local'); localVideo = localVideo}} >
      <i class="fa fa-pause control-bar-icons" aria-hidden="true" title="{translation.camera.pauseButton}"></i>
    </button>
    {/if}
    {#if !(localVideo.isPausedBy == null)}
      <button class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" bind:this="{localVideo.resumeBtn}" on:click={_e => {localVideo.resumeVideo('local'); localVideo = localVideo}}>
        <i class="fa fa-play control-bar-icons" aria-hidden="true" title="{translation.camera.resumeButton}"></i>
      </button>
    {/if}
    <!-- Microphone -->
    {#if !(localVideo.micIsPaused)}
    <button class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" bind:this="{localVideo.micBtn}" on:click={_e => {localVideo.pauseMicrophone(); localVideo = localVideo}} >
      <i class="fa fa-microphone control-bar-icons" aria-hidden="true" title="{translation.camera.micButton}"></i>
    </button>
    {/if}
    {#if (localVideo.micIsPaused)}
    <button class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" bind:this="{localVideo.micBtn}"  on:click={_e => {localVideo.resumeMicrophone(); localVideo = localVideo}} >
      <i class="fa fa-microphone-slash control-bar-icons" aria-hidden="true" title="{translation.camera.resumeMicButton}"></i>
    </button>
    {/if}
    <!-- Sound -->
    {#if false}
    <button class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" bind:this="{localVideo.soundBtn}"  on:click={_e => {localVideo.toggleSound(); localVideo = localVideo}} >
      <i class="fa fa-volume-up control-bar-icons" aria-hidden="true" title="Son"></i>
    </button>
    {/if}
    <!-- Flash -->
    <!-- <button class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" bind:this="{localVideo.flashBtn}" hidden={localVideo = {...localVideo, flashBtn: true}} > -->
      <!-- <i class="fa fa-flash control-bar-icons" aria-hidden="true" title="Flash"></i> -->
    <!-- </button> -->
    <!-- Hang Up -->
    <button class="border-2 rounded-full w-14 h-14 bg-opacity-40 color-alt" bind:this="{localVideo.hangupBtn}" on:click={_e => {localVideo.hangup(); localVideo = localVideo}} >
      <i class="fa fa-stop control-bar-icons" aria-hidden="true" title="{translation.camera.hangupButton}"></i>
    </button>
  </div>
</div>