<script lang="ts">
  import { replace } from 'svelte-spa-router';
  import { fly } from "svelte/transition";
  import {clickOutside} from '../scripts/clickOutside.js';
  import Sidebar from "./SidePanel.svelte";
  import { listOfDevices, restartCall, numberOfReceivedImages, showHeader } from "../scripts/stores.js";
  import { translation } from "../stores.js";
  import { getLang } from '../utils/window.js';

  let camerasIsDisplayed: boolean = false;
  let mobileMenuIsDisplayed: boolean = false;
    // Boolean designed to change some css properties dynamicaly if we are on Altice client side
  let isAltice: boolean = window.location.href.includes('altice');

  let cameraDevices = [];
  listOfDevices.subscribe((value) => {
    cameraDevices = value;
  });

  let Showsidebar: boolean = false;

  function switchCamera(device) {
    restartCall.set({ restart: true, params: device.id });
  }

  function toggleSidePanel() {
    Showsidebar = !Showsidebar; 
    mobileMenuIsDisplayed = !mobileMenuIsDisplayed; 
  }

  function closeMobileHeader(event) {
    mobileMenuIsDisplayed = false; 
  }

  function closeCameraMenu() {
    camerasIsDisplayed = false;
  }
</script>


<style>
  #mobile-header {
    z-index: 5;
  }
</style>


<Sidebar bind:show={Showsidebar} />

<nav class:hidden={!$showHeader}>
  <div class="relative bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="flex justify-between items-center py-6 h-20 md:justify-start md:space-x-10"
      >
        <!-- LOGO -->
        <div class="flex justify-start lg:w-0 lg:flex-1">
            <span class="sr-only">Workflow</span>
            <img on:click="{()=>replace(`#/?lang=${getLang()}`)}" class="h-14 w-14 sm:h-14 pointer"
              src="img/{isAltice ? 'altice' : 'sfrlogo'}.png" alt="{isAltice ? 'Altice' : 'SFR'}"/>
        </div>
        <!-- MENU ICON -->
        <div
          class="-mr-2 -my-2 md:hidden"
          on:click={(e) => {
            mobileMenuIsDisplayed = !mobileMenuIsDisplayed;
          }}
        >
          <button
            type="button"
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-8 w-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav class="hidden md:flex space-x-10">
          {#if !isAltice}
            <div class="relative">
              <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" -->
              <button
                type="button" class="text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-expanded="false" on:click={(e) => {camerasIsDisplayed = !camerasIsDisplayed;}} use:clickOutside on:click_outside={closeCameraMenu}>
                <span></span>
                <!--
                    Heroicon name: solid/chevron-down

                    Item active: "text-gray-600", Item inactive: "text-gray-400"
                  -->
                <svg class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" >
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                </svg>
              </button>

              <!--
                  'Cameras' flyout menu, show/hide based on flyout menu state.

                  Entering: "transition ease-out duration-200"
                    From: "opacity-0 translate-y-1"
                    To: "opacity-100 translate-y-0"
                  Leaving: "transition ease-in duration-150"
                    From: "opacity-100 translate-y-0"
                    To: "opacity-0 translate-y-1"
                -->
              {#if camerasIsDisplayed}
                <div
                  transition:fly={{ y: 1, duration: 500 }}
                  class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                >
                  <div
                    class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                  >
                    <div
                      class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                    >
                      {#each cameraDevices as device}
                        <button
                          on:click={(event) => switchCamera(device)}
                          class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          <!-- Heroicon name: outline/chart-bar -->
                          <div class="ml-4">
                            <p class="text-base font-medium text-gray-900">
                              {device.label}
                            </p>
                          </div>
                        </button>
                      {/each}
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          {/if}
          <!-- RECEIVED IMAGES -->
          <span on:click={(e) => {toggleSidePanel()}} class="text-base font-medium text-gray-500 hover:text-gray-900">
            {translation.header.imagesReceived} <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-indigo-600 rounded-full">{$numberOfReceivedImages}</span>
          </span>
        </nav>
        <!-- APP NAME : Link to main page -->
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <span
            on:click="{()=>replace(`#/?lang=${getLang()}`)}"
            class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
          >
            {translation.appName}
        </span>
        </div>
      </div>
    </div>

    <!--
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      -->
    {#if mobileMenuIsDisplayed}
      <div id='mobile-header' transition:fly={{ y: 1, duration: 500 }} class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" use:clickOutside on:click_outside={closeMobileHeader}>
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <!-- LOGO -->
                <img class="h-12 w-12 sm:h-12 pointer" src="img/{isAltice ? 'altice' : 'sfrlogo'}.png" alt="{isAltice ? 'Altice' : 'SFR'}" />
              </div>
              <div class="-mr-2">
                <button type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" on:click={(e) => {mobileMenuIsDisplayed = !mobileMenuIsDisplayed;}}>
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <!-- CAMERA -->
            {#if !isAltice}
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <button type="button" class="text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  
                        aria-expanded="false" on:click={(e) => { camerasIsDisplayed = !camerasIsDisplayed; }} use:clickOutside on:click_outside={closeCameraMenu}>
                  <span>{translation.header.cameras}</span>
                  <!--
                      Heroicon name: solid/chevron-down

                      Item active: "text-gray-600", Item inactive: "text-gray-400"
                    -->
                  <svg class="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                  </svg>
                </button>

                <!--
                    'Cameras' flyout menu, show/hide based on flyout menu state.

                    Entering: "transition ease-out duration-200"
                      From: "opacity-0 translate-y-1"
                      To: "opacity-100 translate-y-0"
                    Leaving: "transition ease-in duration-150"
                      From: "opacity-100 translate-y-0"
                      To: "opacity-0 translate-y-1"
                  -->
                {#if camerasIsDisplayed}
                  <div
                    transition:fly={{ y: 1, duration: 500 }}
                    class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  >
                    <div
                      class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                    >
                      <div
                        class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                      >
                        {#each cameraDevices as device}
                          <div
                            on:click={(event) => switchCamera(device)}
                            class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                          >
                            <!-- Heroicon name: outline/chart-bar -->
                            <div class="ml-4">
                              <p class="text-base font-medium text-gray-900">
                                {device.label}
                              </p>
                            </div>
                          </div>
                        {/each}
                      </div>
                    </div>
                  </div>
                {/if}
              </nav>
            </div>
            {/if}
            <!-- RECEIVED IMAGES -->
            <div class="mt-6">
              <span on:click={(event) => {toggleSidePanel()}} class="grid grid-cols-2">
                <span class="text-base font-medium text-gray-500 hover:text-gray-900">
                  {translation.header.imagesReceived} <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-indigo-600 rounded-full">{$numberOfReceivedImages}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
</nav>
