import Camera from './pages/Camera.svelte';
import Feedback from './pages/Feedback.svelte';
import End from './pages/End.svelte';
import Home from './pages/Home.svelte';
export default {
    '/': Home,
    '/client/:room': Camera,
    '/feedback/:room': Feedback,
    '/end': End,
    '/:room': Home,
};
