<script lang="ts">
  import { push } from 'svelte-spa-router';
  import Button from '../components/Button.svelte';
  import Notification from '../components/Notification.svelte';
  import { ElasticLogger } from '../scripts/monitoring.js';
  import { translation } from '../stores';
  import { getLang } from '../utils/window';

  export let params: Record<string, string> = {};

  let elasticLogger = new ElasticLogger();
  // Boolean designed to change some css properties dynamicaly if we are on Altice client side
  let isAltice: boolean = window.location.href.includes('altice');

  let roomIsdefined = (params.room !== null && params.room !== undefined); 
  let roomId: number;
  let agreed: boolean = false;
  let alertNotification: string = '';

  if (roomIsdefined) {
    roomId = parseInt(params.room);
    let logData = {
      room: roomId, 
      status: "Connected",
      page: "Home",
      message: "Client is connected on home page",
      distributeur: isAltice ? 'Altice' : 'SFR'
    };
    elasticLogger.send(logData);
  }

  // Handle values and redirect to video
  function submit(): void {
    if (!agreed) { 
      alertNotification = translation.alert.cguMissing;
    } else if (!roomId) {
      alertNotification = translation.alert.roomNumberMissing;
    } else {
      push(`/client/${roomId}?lang=${getLang()}`);
    }
  
  }
</script>
<Notification  bind:alertNotification />
<div class="text-center p-1">
  <!-- WELCOME TITLE -->
  <header class="justify-center m-5 flex-col items-center">
    <img width="70" height="50" src="/img/camera{isAltice ? '-white' : ''}.png" alt="camera" />
    <h1 class={isAltice ? 'text-white' : ''}>
      {translation.appName}
    </h1>
  </header>

  <!-- INTRODUCTION SECTION -->
  <section class="card px-4 text-start">
    <p>
      {translation.intro.part1}<br/><br/>
      {translation.intro.part2}
    </p>
    <ul>
      <li>{translation.intro.li1}</li>
      <li>{translation.intro.li2}</li>
      <li>{translation.intro.li3}</li>
    </ul>

    <form>
      {#if !roomIsdefined}
      <div class="m-5" >
        <label>
          {translation.intro.inputRoomNumber}
          <input bind:value={roomId} style="max-width: 10rem;" type="number" />
        </label>
      </div>
      {/if}
  
      <label class="text-sm flex items-start">
        <input style="margin: {isAltice ? '0.325' : '0.2'}rem;" bind:checked={agreed} type="checkbox" />
        <div> {isAltice ? translation.intro.cgu1.replace('SFR', 'Altice') : translation.intro.cgu1} 
          <br/> {translation.intro.cgu2}
          <a class="text-info" href="https://www.sfr.fr/politique-de-protection-des-donnees-personnelles.html"
          target="_blank">{translation.intro.policy1}</a> {translation.intro.policy2}
        </div>
      </label>
      <p style="margin: {isAltice ? '1.325' : '1.2'}rem;" class="text-sm text-info text-bold">
        <a href="/doc/CGU{isAltice ? '_DO' : ''}.pdf" target="_blank">{translation.intro.cguLink}</a>
      </p>
      <div class="text-center m-t-xl">
        <Button on:click={submit}>
          {translation.intro.startCallButton}
        </Button>
      </div>
    </form>
  </section>
</div>

<style>
  .text-info:visited {
    color: var(--info);
  }
  li:before {
    content: '- ';
  }
</style>
