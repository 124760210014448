<script lang="ts">
  import { replace } from 'svelte-spa-router';
  import Rate from 'svelte-rate-it/Rate.svelte';
  import Button from '../components/Button.svelte';
  import { ElasticLogger } from '../scripts/monitoring.js';
  import { translation } from '../stores';
  import { getLang } from '../utils/window';

  let comments: string;
  let rating: number;

  function afterRate(rate: number): void {
    rating = rate;
  }

  function submit(): void {
    console.log(comments, rating);
    // Loading Elastic Logger
    let elasticLogger = new ElasticLogger();
    let logData = { 
      status: "Feedback",
      page: "Feedback", 
      message: "Client returned feedback",
      comment: comments,
      satisfaction: rating,
      distributeur: window.location.href.includes("altice") ? 'Altice' : 'SFR'
    };
    elasticLogger.send(logData);
    replace(`/end?lang=${getLang()}`);
  }

</script>

<style>
  p {
    @apply mb-2;
  }
</style>

<div class="card" style="margin-top:10vh;">
  <section class="font-semibold text-center text-2xl m-b-3xl">
    <h2>{translation.feedback.title}</h2>
  </section>
  <section class="font-semibold m-t-3xl">
    <p>{translation.feedback.text1}</p>
    <p>{translation.feedback.text2}</p>
    <p>{translation.feedback.text3}</p>
    <form>
      <div class="text-center m-t-3xl">
        <Rate {afterRate} />
      </div>
      <label class="block">
        {translation.feedback.commentary}
        <textarea bind:value={comments}
                  class="text-black w-full h-20"></textarea>
      </label>
      <div class="text-center mt-2">
        <Button on:click={submit} disabled={!comments && !rating}>
          {translation.feedback.submitButton}
        </Button>
      </div>
    </form>
  </section>
</div>


