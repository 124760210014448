import { writable } from 'svelte/store';

export const showModalImg = writable(false);
export const showImgNotif = writable(false);
export const linkToImage = writable(null);
export const listOfDevices = writable([]);
export const restartCall = writable({ restart: false, params: null });
export const dacUrl = writable('');
export const sessionState = writable({
    sessionType: null,
    audioIsEnabled: null,
    videoIsPausedBy: null,
    micIsPaused: false,
    audioIsMuted: false,
    flashIsEnabled: false,
    lang: 'fr'
});
export const videoConstraints = writable({});
export const displayRemoteVideo = writable(false);
export const showHeader = writable(true);
export const numberOfReceivedImages = writable(0);

/**
 * ConnectionStatus can take the following values : 
 *  - Loading (init)
 *  - Error
 *  - Connected
 */
function createConnectionStatus() {
    const { subscribe, set } = writable('Loading');
    return {
        subscribe,
        set: (value) => {
            set(value);
            console.log('Connection Status : ', value);
        }
    }
}
export const connectionStatus = createConnectionStatus();

/**
 * Text to display to client when ConnectionStatus is on Error
 * (ConnectionStatus is therefore automaticly set to Error)
 */
function createConnectionError() {
    const { subscribe, set } = writable('Loading');
    return {
        subscribe,
        set: (value) => {
            set(value);
            connectionStatus.set('Error');
            console.log('Connection Error : ', value);
        }
    }
}
export const connectionError = createConnectionError();