<script>
  export let alertNotification = null;
  let alertTimeOut = null;
  let displayTimeOut = false;
  $: alertNotification, setTimeOutAlert();
  function setTimeOutAlert() {
    
    displayTimeOut = !!alertNotification;
    if (alertTimeOut !== null) {
      clearTimeout(alertTimeOut);
    }
    alertTimeOut = setTimeout(() => (displayTimeOut = false), 5000);
  }
</script>

{#if displayTimeOut}
  <div
    style="position:absolute;bottom:0;width:100vw;"
    class="px-4 py-3 leading-normal text-red-100 bg-red-700"
    role="alert"
  >
    <p>{alertNotification}</p>
  </div>
{/if}
