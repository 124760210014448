export const translations = {
    fr: {
        appName: "Diag Caméra",
        alert: {
            connexionError: "Erreur de connexion",
            cguMissing: "Veuillez accepter les CGU.",
            permissionDeniedError1: "Vous avez refusé l'accès à la caméra et/ou au microphone.",
            permissionDeniedError2: "Veuillez modifier les préférences de votre navigateur et réactualiser la page.",
            roomNumberMissing: "Veuillez entrer le numéro de session.",
            sessionFullError: "Cette session est complète ou inexistante.",
            spinner: "Connexion en cours...",
            activePosition: "Veuillez activer votre position.",
        },
        camera: {
            hangupButton: "Raccrocher",
            imagesReceived: "Images reçues",
            micButton: "Microphone",
            newImageNotification: "Vous avez reçu une nouvelle image.",
            pauseButton: "Pause",
            resumeButton: "Reprendre",
            resumeMicButton: "Microphone",
            screenSharingButton1: "Veuillez cliquer sur le bouton ci-dessous pour partager votre écran et démarrer l'appel vidéo avec le conseiller.",
            screenSharingButton2: "Démarrer le partage d'écran",
        },
        end: {
            bye: "À bientôt !",
            text: "Nous vous remercions pour votre retour! Vous pouvez maintenant fermer la fenêtre.",
            title: "Merci",
        },
        feedback: {
            commentary: "Commentaires :",
            submitButton: "Envoyer",
            text1: "La session vidéo est terminée.",
            text2: "Vous pouvez répondre aux questions ci-dessous dans le cadre de l'amélioration du service.",
            text3: "La visio-assistance a-t-elle été utile pour résoudre votre problème ?",
            title: "Questionnaire",
        },
        header: {
            cameras: "Caméras",
            imagesReceived: "Images reçues",
        },
        intro: {
            cgu1: "J’accepte le traitement par SFR et ses sous-traitants (hors UE) du flux vidéo de mon téléphone portable aux fins de dépannage.",
            cgu2: "Ces données ne seront pas conservées à la fin de la session.",
            cguLink: "Lire les conditions générales d'utilisation.",
            inputRoomNumber: "Veuillez entrer votre numéro de room :",
            li1: "Mettre en pause",
            li2: "Reprendre l'appel vidéo",
            li3: "Raccrocher",
            part1: "Diag Caméra est un outil permettant un support via la caméra de votre téléphone ou le partage d'écran de votre ordinateur.",
            part2: "Dès le début l'appel video, vous pourrez:",
            policy1: "En savoir plus",
            policy2: "sur notre politique de traitement des données.",
            startCallButton: "Démarrer l'appel vidéo"
        },
        geolocalisation: {
            latitude: 'Latitude',
            longitude: 'Longitude'
        }
    },
    es: {
        alert: {
            connexionError: "Error de conexión",
            cguMissing: "Por favor, acepte los Términos y Condiciones de Uso.",
            permissionDeniedError1: "Ha denegado el acceso a la cámara y/o al micrófono.",
            permissionDeniedError2: "Por favor, cambie las preferencias de su navegador y actualice la página.",
            roomNumberMissing: "Por favor, ingrese el número de sesión.",
            sessionFullError: "Esta sesión está completa o no existe.",
            spinner: "Conectando...",
            activePosition: "Por favor, active su ubicación.",
        },
        appName: "Diag Cámara",
        camera: {
            hangupButton: "Colgar",
            imagesReceived: "Imágenes recibidas",
            micButton: "Micrófono",
            newImageNotification: "Ha recibido una nueva imagen.",
            pauseButton: "Pausa",
            resumeButton: "Reanudar",
            resumeMicButton: "Micrófono",
            screenSharingButton1: "Por favor, haga clic en el botón de abajo para compartir su pantalla e iniciar la llamada de video con el asesor.",
            screenSharingButton2: "Iniciar compartir pantalla",
        },
        end: {
            bye: "¡Hasta pronto!",
            text: "¡Le agradecemos que haya vuelto! Ahora puede cerrar esta ventana.",
            title: "Gracias",
        },
        feedback: {
            commentary: "Comentarios:",
            submitButton: "Enviar",
            text1: "La sesión de video ha terminado.",
            text2: "Con el objectivo de mejorar nuestro servicio. Sirvase responder las siguientes preguntas:",
            text3: "¿Considera que fue util la vidéo llamada para resolver su demanda?",
            title: "Cuestionario",
        },
        header: {
            cameras: "Cámaras",
            imagesReceived: "Imágenes recibidas",
        },
        intro: {
            cgu1: "Acepto los terminos y condiciones de SFR y sus subcontratistas (fuera de la union européa) del flujo de la llamada en vidéo a mi móvil para servicios de resolucion de problémas de mi servicio de teléfonia fija o móvil.",
            cgu2: "Esta informqcion no sera conservados al final de la sesión.",
            cguLink: "Leer los términos y condiciones de utilizacion.",
            inputRoomNumber: "Escriba el n° de la sala:",
            li1: "Poner en pausa",
            li2: "Reanudar la videollamada",
            li3: "Poner fin a la llamada",
            part1: "Diag Cámara es un aplicativo que permite soporte via la cámara de su Movil o via la pantalla compartida de vuestro ordenador.",
            part2: "Durante la vidéo llamada, usted podrá:",
            policy1: "Saber más",
            policy2: "sobre nuestra política de tratamiento de datos.",
            startCallButton: "Comenzar la vidéo llamada"
        },
        geolocalisation: {
            latitude: 'Latitud',
            longitude: 'Longitud'
        }
    }
};
