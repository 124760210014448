export function getLang() {
    let lang = "fr";
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("lang")) {
        lang = urlParams.get("lang");
    }
    else if (window.location.href.includes('?lang=')) { // we have to check this because of the # of the spa router which makes the urlParams undefined
        const index = window.location.href.indexOf('?lang=');
        const langPart = window.location.href.substring(index + 6);
        if (langPart.includes('&')) {
            const lastIndex = langPart.indexOf('&');
            lang = langPart.substring(0, lastIndex);
        }
        else {
            lang = langPart;
        }
    }
    console.log('lang is ' + lang);
    return lang;
}
