<script lang="ts">
  import '../node_modules/tricss/dist/style.css';
  import Tailwind from './Tailwind.svelte';
  import Router from 'svelte-spa-router';
  import routes from './routes';
  import Header from './components/Header.svelte';
</script>

<svelte:head>
  <link rel='shortcut icon' type="image/png" href='img/sfr-logo-small.png' />
  <link rel='icon' type="image/png" href='img/sfr-logo-small.png' />
  <script type="text/javascript" src="https://webrtc.github.io/adapter/adapter-latest.js"></script>
  {#if window.location.href.includes('altice')}
    <link rel="stylesheet" href="themeAltice.css" />
  {/if}
</svelte:head>
<div>
  <Tailwind />
  
  <main class="page flex flex-col w-screen h-screen overflow-y-auto">
    <Header />
    <Router {routes} />
  </main>
  
</div>


