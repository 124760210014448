<button class="btn btn-primary" {...$$restProps}
        on:click|preventDefault>
  <slot />
</button>

<style>

[disabled] {
  cursor: not-allowed;
  filter: grayscale(70%);
}
</style>
