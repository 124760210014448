<script>
  import { fly } from 'svelte/transition';
  import { clickOutside } from '../scripts/clickOutside.js';
    import { translation } from '../stores.js';
  export let show = false;
  
  function closeSidePanel(event) {
    show = false;
  }
  
  </script>

  <nav id='left-side-panel' class="lg:w-1/3 sm:w-1/2 w-full" transition:fly={{x: -250, opacity: 1}}  hidden={!show}  use:clickOutside on:click_outside={closeSidePanel} >
    <div id="side-panel" class="sidepanel">
        <div id="side-panel-header" class="pb-2.5 border-b">
            <button class="text-xl absolute right-4" on:click={closeSidePanel}>&times;</button>
            <h2 class="text-xl" style="margin:unset;">{translation.camera.imagesReceived}</h2>
        </div>
    </div>
  </nav>
    <!-- Modal for displaying image -->
    


<!-- <Modal bind:show={modal_show} /> -->


		
<style>
nav {
  position: fixed;
  left: 0;
  height: 100%;
  padding: 2rem 1rem 0.6rem;
  border: 1px solid lightgray;
  background: var(--background-alt);
  box-shadow: 5px 5px 5px 0px lightgray;
  overflow-y: auto;
  z-index: 5;
}
</style>